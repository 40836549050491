import * as crypto from 'crypto'
import { env } from 'process'

import { faker } from '@faker-js/faker'
import { type ClassValue, clsx } from 'clsx'
import { saveAs } from 'file-saver'
import Papa from 'papaparse'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function parseJwt(token: string) {
  if (!token) {
    return
  }
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  return JSON.parse(atob(base64))
}

export function generateCSV(data: Record<string, string | number | Date>[], filename: string) {
  // Convert to CSV using PapaParse
  const csv = Papa.unparse(data)

  // Trigger download
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
  saveAs(blob, filename)
}

export function trimEllip(originalString: string, length: number) {
  try {
    return originalString.length > length ? originalString.substring(0, length) + '...' : originalString
  } catch {
    return ''
  }
}

export function getEnv(key: string): string {
  const env = process.env[key]
  if (!env) {
    throw new Error(`The ${key} environment variable was not found!`)
  }
  return env
}

export function humanReadableMoneyFormatter(value: number) {
  if (value === undefined || value === null || isNaN(value) || value === 0) {
    return '$0'
  }
  if (value < 1000) {
    return value % 1 === 0 ? `$${value}` : `$${Math.round(value)}`
  }

  if (value < 1000000) {
    return `$${Math.round(value / 1000)}K`
  }

  return `$${Math.round(value / 1000000)}M`
}

export async function copyToClipboard(text: string): Promise<void> {
  try {
    await navigator.clipboard.writeText(text)
  } catch (err) {
    throw new Error('Failed to copy to clipboard')
  }
}

export function encryptCallRecordingUrl(content: Record<string, unknown>) {
  const recordingUrl = content['call_recording_url'] as string
  if (recordingUrl) {
    if (recordingUrl.includes('twilio.com')) {
      // Keeping this here for old recordings
      const urlToEncrypt = 'twilio|' + recordingUrl.split('https://api.twilio.com/')[1]
      const encryptedUrl = encrypt(urlToEncrypt)
      content['call_recording_url'] = `/api/call-recording/${encryptedUrl}`
    } else {
      const urlToEncrypt = 'storage|' + recordingUrl.split('https://storage.cloud.google.com/')[1]
      const encryptedUrl = encrypt(urlToEncrypt)
      content['call_recording_url'] = `/api/call-recording/${encryptedUrl}`
    }
  }
  return content
}

const getEncryptionKey = () => {
  if (!env.CALL_RECORDING_ENCRYPTION_KEY) {
    throw new Error('CALL_RECORDING_ENCRYPTION_KEY is not set')
  }
  return Buffer.from(env.CALL_RECORDING_ENCRYPTION_KEY, 'hex')
}

// Simple AES encryption function
function encrypt(text: string) {
  const key = getEncryptionKey()
  const iv = crypto.randomBytes(16) // random initialization vector
  const cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(key), iv)
  let encrypted = cipher.update(text)
  encrypted = Buffer.concat([encrypted, cipher.final()])
  return iv.toString('hex') + ':' + encrypted.toString('hex')
}

export function decrypt(text: string) {
  const key = getEncryptionKey()
  const textParts = text.split(':')
  const iv = Buffer.from(textParts.shift()!, 'hex')
  const encryptedText = Buffer.from(textParts.join(':'), 'hex')
  const decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(key), iv)
  let decrypted = decipher.update(encryptedText)
  decrypted = Buffer.concat([decrypted, decipher.final()])
  return decrypted.toString()
}

export const generateFakePIIdata = () => {
  const fullName = getRandomCelebrity()
  const firstName = fullName.split(' ')[0]
  const lastName = fullName.split(' ')[1]
  const email = `${firstName.toLowerCase()}_${lastName.toLowerCase()}@phiscrambled.com`
  const phone = `+1945${faker.number.bigInt({ min: 1000000, max: 9999999 })}`
  return { firstName, lastName, fullName, email, phone }
}

export function getPreviousTimeWindow(
  startDate: Date,
  endDate: Date
): { previousStartDate: Date; previousEndDate: Date } {
  const start = startDate
  const end = endDate

  // Calculate the duration in days between the start and end date
  const durationInDays = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24) + 1

  // Calculate the previous end date by subtracting the duration from the start date
  const previousEnd = new Date(start.getTime() - 1000 * 60 * 60 * 24)
  // Calculate the previous start date by subtracting the duration from the previous end date
  const previousStart = new Date(previousEnd.getTime() - (durationInDays - 1) * (1000 * 60 * 60 * 24))

  // Format the dates to 'YYYY-MM-DD'
  const previousStartDate = previousStart
  const previousEndDate = previousEnd

  return { previousStartDate, previousEndDate }
}

export function formatBytes(bytes: number) {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = 2
  const sizes = ['Bytes', 'KB', 'MB', 'GB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function getRandomCelebrity() {
  return faker.helpers.arrayElement(celebrities)
}

const celebrities = [
  'Kanye West',
  'Taylor Swift',
  'Elton John',
  'Madonna Ciccone',
  'Michael Jackson',
  'Elvis Presley',
  'Whitney Houston',
  'Bruce Springsteen',
  'Lady Gaga',
  'Ariana Grande',
  'Drake Graham',
  'Justin Timberlake',
  'Rihanna Fenty',
  'Katy Perry',
  'Usher Raymond',
  'Miley Cyrus',
  'Jennifer Lopez',
  'Bruno Mars',
  'Snoop Dogg',
  'Michael Jordan',
  'Serena Williams',
  'Tom Brady',
  'LeBron James',
  'Tiger Woods',
  'Muhammad Ali',
  "Shaquille O'Neal",
  'Kobe Bryant',
  'Venus Williams',
  'Mike Tyson',
  'Dwayne Johnson',
  'Arnold Schwarzenegger',
  'Hulk Hogan',
  'Ronda Rousey',
  'Simone Biles',
  'Michael Phelps',
  'Carl Lewis',
  'Usain Bolt',
  'Jesse Owens',
  'Peyton Manning',
  'Brett Favre',
  'Brad Pitt',
  'Meryl Streep',
  'Leonardo DiCaprio',
  'Scarlett Johansson',
  'Tom Hanks',
  'Angelina Jolie',
  'Johnny Depp',
  'Jennifer Lawrence',
  'Morgan Freeman',
  'Denzel Washington',
  'Will Smith',
  'Julia Roberts',
  'Sandra Bullock',
  'Chris Evans',
  'Chris Hemsworth',
  'Emma Stone',
  'Reese Witherspoon',
  'Natalie Portman',
  'Charlize Theron',
  'Anne Hathaway',
  'Harrison Ford',
  'George Clooney',
  'Matt Damon',
  'Ben Affleck',
  'Mark Wahlberg',
  'Sylvester Stallone',
  'Al Pacino',
  'Robert De Niro',
  'Clint Eastwood',
  'Jack Nicholson',
  'Samuel Jackson',
  'Bruce Willis',
  'Tom Cruise',
  'John Travolta',
  'Kevin Costner',
  'Keanu Reeves',
  'Nicolas Cage',
  'Dustin Hoffman',
  'Robin Williams',
  'Richard Gere',
  'Mel Gibson',
  'Steve Carell',
  'Eddie Murphy',
  'Jim Carrey',
  'Adam Sandler',
  'Will Ferrell',
  'Bill Murray',
  'Dan Aykroyd',
  'John Belushi',
  'Chevy Chase',
  'Ben Stiller',
  'Owen Wilson',
  'Vince Vaughn',
  'Jonah Hill',
  'Seth Rogen',
  'James Franco',
  'Zach Galifianakis',
  'Drake Bell',
  'Nick Jonas',
  'Joe Jonas',
  'Kevin Jonas',
  'Brandon Flowers',
  'Adam Levine',
  'Blake Shelton',
  'Gwen Stefani',
  'Christina Aguilera',
  'Britney Spears',
  'Justin Bieber',
  'Selena Gomez',
  'Shawn Mendes',
  'Camila Cabello',
  'Billie Eilish',
  "Finneas O'Connell",
  'Lana Del Rey',
  'Kendrick Lamar',
  'J. Cole',
  'Cardi B',
  'Nicki Minaj',
  'Post Malone',
  'Lizzo Jefferson',
  'Travis Scott',
  'Alicia Keys',
  'John Legend',
  'Kelly Clarkson',
  'Carrie Underwood',
  'Miranda Lambert',
  'Luke Bryan',
  'Blake Shelton',
  'Garth Brooks',
  'Tim McGraw',
  'Faith Hill',
  'Dolly Parton',
  'Kenny Chesney',
  'Jason Aldean',
  'Eric Church',
  'Keith Urban',
  'Chris Stapleton',
  'Luke Combs',
  'Dierks Bentley',
  'Thomas Rhett',
  'Sam Hunt',
  'Shania Twain',
  'Reba McEntire',
  'Martina McBride',
  'Trisha Yearwood',
  'George Strait',
  'Alan Jackson',
  'Willie Nelson',
  'Merle Haggard',
  'Waylon Jennings',
  'Hank Williams',
  'Johnny Cash',
  'Kris Kristofferson',
  'Loretta Lynn',
  'Patsy Cline',
  'Tammy Wynette',
  'June Carter Cash',
  'Roy Orbison',
  'Buddy Holly',
  'Ritchie Valens',
  'James Brown',
  'Ray Charles',
  'Aretha Franklin',
  'Sam Cooke',
  'Otis Redding',
  'Stevie Wonder',
  'Marvin Gaye',
  'Diana Ross',
  'The Supremes',
  'The Temptations',
  'Gladys Knight',
  'Smokey Robinson',
  'Lionel Richie',
  'Rick James',
  'Tina Turner',
  'Donna Summer',
  'Prince Nelson',
  'Whitney Houston',
  'Janet Jackson',
  'Michael Jackson',
  'Diana Ross',
  'Beyoncé Knowles',
  'Jay-Z Carter',
  'Kanye West',
  'Rihanna Fenty',
  'Drake Graham',
  'Cardi B',
  'Nicki Minaj',
  'Travis Scott',
  'The Weeknd',
  'Bruno Mars',
  'Ariana Grande',
  'Taylor Swift',
  'Katy Perry',
  'Miley Cyrus',
  'Selena Gomez',
  'Justin Bieber',
  'Shawn Mendes',
  'Camila Cabello',
  'Billie Eilish',
  "Finneas O'Connell",
  'Bruce Lee',
  'Jackie Chan',
  'Jet Li',
  'Donnie Yen',
  'Simu Liu'
]

export function getNumericPhoneNumber(phoneStr: string) {
  // Remove all non-numeric characters
  const cleaned = phoneStr.replace(/\D/g, '')
  const phoneNumber = Number(cleaned)

  return phoneNumber
}
